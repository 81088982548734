import { lazy, string, object, mixed, array, number, ref, date } from "yup";
import { colorCodeRegex } from "./common-service";
import {
  QUESTION_TYPE,
  FUNNEL_DESIGNS,
  FUNNEL_TYPE_FILTER
} from "../components/strings";

const colorFieldMessages = {
  invalidCode: "Invalid color code (eg: #ffffff)",
  minLength: "Must be 7 digit code"
};
const errorTextMessage = "Required";

const YupSelectFieldValidation = lazy((value) => {
  if (value) {
    switch (typeof value) {
      case "string":
        return string().required(errorTextMessage).nullable();
      case "object":
        return object().shape({
          _id: string().required(errorTextMessage).nullable()
        });
      default:
        return mixed();
    }
  } else {
    return string().required(errorTextMessage).nullable();
  }
});

const QACommonSchema = object().shape({
  funnelQA: array(
    object().shape({
      text: string().required(errorTextMessage),
      answers: array(
        object().shape({
          text: string().when("ansType", (ansType, field) =>
            ansType === QUESTION_TYPE.ICONS ||
            ansType === QUESTION_TYPE.TEXTONLY
              ? field.required(errorTextMessage)
              : field
          ),
          asset: object()
            .shape({})
            .when("ansType", {
              is: QUESTION_TYPE.ICONS,
              then: object().shape({}).required(errorTextMessage).nullable(),
              otherwise: object().shape({}).nullable().notRequired()
            })
        })
      ).when("type", {
        is: (typeVal) => {
          if (
            typeVal !== QUESTION_TYPE.FREE_TEXT_FIELD &&
            typeVal !== QUESTION_TYPE.SLIDER
          ) {
            return true;
          } else {
            return false;
          }
        },
        then: array().min(1, "This question must have at least 1 answer*")
      }),
      range: object()
        .shape({})
        .when("type", {
          is: QUESTION_TYPE.SLIDER,
          then: object().shape({
            min: number().typeError(errorTextMessage),
            max: number().typeError(errorTextMessage)
            // step: number().typeError(errorTextMessage),
          }),
          otherwise: object().shape({}).notRequired()
        })
    })
  ).min(1, "Funnels QA must have at least 1 question*")
});

const DesignCommonSchema = object().shape({
  design: object()
    .shape({
      policyText: string().when("policyDisplay", {
        is: true,
        then: string().required(errorTextMessage),
        otherwise: string().notRequired()
      })
    })
    .when("funnelDesign", {
      is: FUNNEL_DESIGNS.CLASSIC,
      then: object().shape({
        baseColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        companyLogoBackground: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength)
      }),
      otherwise: object().shape({
        baseColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernBackgroundOne: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernBackgroundTwo: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernHeaderLabelsColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernNextButtonTextColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernSubmitButtonTextColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernSubmitButtonBackground: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernNextButtonBackground: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernNextButtonHoverBackground: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernContrastColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        additionalTaglineColor: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        additionalTaglineBackground: string()
          .required(errorTextMessage)
          .matches(colorCodeRegex, {
            message: colorFieldMessages.invalidCode,
            excludeEmptyString: false
          })
          .min(7, colorFieldMessages.minLength),
        modernHeadline: string().required(errorTextMessage),
        modernFunnelHeadline: string().required(errorTextMessage),
        modernFormHeading: string().required(errorTextMessage),
        modernFormInfoLabelOne: string().required(errorTextMessage),
        modernFormInfoLabelTwo: string().required(errorTextMessage),
        modernFormInfoLabelThree: string().required(errorTextMessage),
        additionalTaglineLabelOne: string().required(errorTextMessage),
        additionalTaglineLabelTwo: string().required(errorTextMessage),
        modernNextButtonText: string().required(errorTextMessage),
        modernSubmitButtonText: string().required(errorTextMessage),
        modernHeaderCallLabel: string().required(errorTextMessage),
        modernHeaderPhoneNumber: number()
          .typeError(errorTextMessage)
          .required(errorTextMessage),
        modernHeaderEmailAddress: string().email().required(errorTextMessage),
        uspIcons: array(
          object().shape({
            label: string().required(errorTextMessage),
            icon: string().required(errorTextMessage),
            color: string()
              .required(errorTextMessage)
              .matches(colorCodeRegex, {
                message: colorFieldMessages.invalidCode,
                excludeEmptyString: false
              })
              .min(7, colorFieldMessages.minLength)
          })
        ).min(1, "USP Icons list is empty")
      })
    })
});

const ThankyouCommonSchema = object().shape({
  thankyouPage: object().shape({
    redirectUrl: string().when("redirectLead", {
      is: true,
      then: string().required(errorTextMessage),
      otherwise: string().notRequired()
    })
  })
});
const TextElementsCommonSchema = object().shape({
  textElements: object().shape({
    numberCheck: string(errorTextMessage).required(errorTextMessage),
    phoneScreenHeading: string().when("enablePhoneWithTwoSteps", {
      is: true,
      then: string().required(errorTextMessage),
      otherwise: string().notRequired()
    })
  })
});

const GeneralFunnelSchema = object().shape({
  general: object().shape({
    funnelName: string().required(errorTextMessage),
    pricePerInvoiceUnit: number().typeError(errorTextMessage)
  }),
  customer: YupSelectFieldValidation,
  category: YupSelectFieldValidation,
  hubspotProjectId: string().required(errorTextMessage)
});

export const TemplateModalSchema = QACommonSchema.shape({
  name: string().required(errorTextMessage)
});

export const TemplateFormSchema = TemplateModalSchema.concat(DesignCommonSchema)
  .concat(TextElementsCommonSchema)
  .concat(ThankyouCommonSchema);

export const FunnelFacebookFormSchema = GeneralFunnelSchema.concat(
  object().shape({
    facebookForm: object().shape({
      forms: array(
        object()
          .shape({
            id: string().required(errorTextMessage)
          })
          .nullable()
          .required(errorTextMessage)
      ).min(1, "Facebook page must have at least 1 form*"),
      page: object()
        .shape({
          id: string().required(errorTextMessage)
        })
        .nullable()
        .required(errorTextMessage)
    })
  })
);

export const FunnelBasicFormSchema = QACommonSchema.concat(DesignCommonSchema)
  .concat(TextElementsCommonSchema)
  .concat(ThankyouCommonSchema)
  .concat(GeneralFunnelSchema)
  .concat(
    object().shape({
      subDomain: string().required(errorTextMessage)
    })
  );

export const LoginFormSchema = object().shape({
  email: string().required(errorTextMessage),
  password: string().required(errorTextMessage)
});

export const ForgotPasswordFormSchema = object().shape({
  email: string().required(errorTextMessage)
});
export const ResetPasswordFormSchema = object().shape({
  password: string().required(errorTextMessage),
  confirmPassword: string().oneOf(
    [ref("password"), null],
    "Passwords must match"
  )
});

export const ImportFunnelSchema = object().shape({
  importUrl: string().required(errorTextMessage)
});

export const CustomerFormSchema = object().shape({
  firstName: string().required(errorTextMessage).nullable(),
  lastName: string().required(errorTextMessage).nullable(),
  email: string().email().required(errorTextMessage)
});

export const CategoryFormSchema = object().shape({
  name: string().required(errorTextMessage).nullable(),
  leadPriceBenchmark: number()
    .typeError(errorTextMessage)
    .required(errorTextMessage),
  leadQualifiedPriceBenchmark: number()
    .typeError(errorTextMessage)
    .required(errorTextMessage)
});

export const TagFormSchema = object().shape({
  name: string().required(errorTextMessage).nullable()
});

export const DomainDialogSchema = object().shape({
  domain: string().required(errorTextMessage),
  subDomain: string().required(errorTextMessage)
});

export const IntegrationFormSchema = object().shape({
  webHookUrl: string().required(errorTextMessage)
});

export const UserCommonSchema = object().shape({
  firstName: string().required(errorTextMessage),
  lastName: string().required(errorTextMessage),
  status: string().required(errorTextMessage),
  role: string().required(errorTextMessage)
});

// export const assignUserSchema = object().shape({
//   user: YupSelectFieldValidation,
//   token: string().required(errorTextMessage)
// });

export const CreateUserSchema = UserCommonSchema.concat(
  object().shape({
    email: string().required(errorTextMessage),
    password: string().required(errorTextMessage)
  })
);

export const ChangePasswordFormSchema = object().shape({
  oldPassword: string().required(errorTextMessage),
  newPassword: string().required(errorTextMessage),
  cNewPassword: string()
    .required(errorTextMessage)
    .oneOf([ref("newPassword"), null], "Passwords must match")
});

export const LeadUpdateSchema = object().shape({
  leadEmail: string().email().required(errorTextMessage),
  leadPhone: string().required(errorTextMessage),
  leadName: string().required(errorTextMessage)
});

export const FunnelFiltersFormSchema = object().shape({
  heading: string().required(errorTextMessage),
  message: string().required(errorTextMessage)
});

export const AdCopyScehma = object().shape({
  companyName: string().required(errorTextMessage),
  category: YupSelectFieldValidation,
  funnelUrl: string().required(errorTextMessage)
});

export const AdDuplicatorScehma = object().shape({
  viewId: string().required(errorTextMessage)
});

const ContactCloudSelectSchema = (filter) =>
  filter
    ? object()
        .shape()
        .when("for", {
          is: filter,
          then: object().shape({
            id: string().required(errorTextMessage).nullable()
          }),
          otherwise: object().shape({}).notRequired()
        })
    : object().shape({
        id: string().required(errorTextMessage).nullable()
      });

export const ContactCloudSetupSchema = object().shape({
  userId: ContactCloudSelectSchema(),
  productId: ContactCloudSelectSchema(FUNNEL_TYPE_FILTER.LEAD),
  jobId: ContactCloudSelectSchema(FUNNEL_TYPE_FILTER.EMPLOYEE)
});

export const EmailIntegrationFormSchema = object().shape({
  email: string().required(errorTextMessage)
});

export const AppointmentFormSchema = object().shape({
  appointmentLink: string().required(errorTextMessage)
});

export const LeadServiceCompanyNameSchema = object().shape({
  companyNameForLeadAgent: string()
    .required(errorTextMessage)
    .when(["enabled", "enabledPlus"], (enabled, enabledPlus, schema) => {
      return enabled || enabledPlus ? schema.required() : schema.notRequired();
    })
});

export const GalleryFormSchema = object().shape({
  category: YupSelectFieldValidation
});

export const SuggestionGallerySchema = object().shape({
  link: string().required(errorTextMessage)
});

export const TestedLinksSchema = object().shape({
  linkToCampaign: string().required(errorTextMessage),
  linkToFunnel: string().required(errorTextMessage),
  category: YupSelectFieldValidation
});

export const AdvertisingAccountLinkFormSchema = object().shape({
  accountLink: string().required(errorTextMessage)
});

export const ContactLaterDialogSchema = object().shape({
  date: date().typeError(errorTextMessage).required()
});
