import {
  Tab,
  Row,
  Col,
  Form,
  Tabs,
  Button,
  Tooltip,
  InputGroup,
  OverlayTrigger
} from "react-bootstrap";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
  useOutletContext
} from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { useLocation } from "react-router";
import {
  faEye,
  faCodeBranch,
  faArrowDownShortWide
} from "@fortawesome/free-solid-svg-icons";
import { useRef, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import {
  FORM_TYPE,
  SELECT_TAB,
  BASE_COUNTRY,
  FB_FORM_MODE,
  FUNNEL_DESIGNS,
  FB_CONVERSION_API_OPTS
} from "../strings";
import {
  Legend,
  FieldSet,
  CustomCard,
  HorizontalDivider
} from "../common-styling";
import Loader from "../loader/loader";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import {
  designObj,
  textElemObj,
  pixelOptions,
  countryOptions,
  thankYouPageObj
} from "../common-confgs/common-vars";
import {
  saveFbForm,
  getFbPages,
  getFbForms,
  updateFunnel,
  getTemplateById,
  createDraftFunnel,
  changeVersionById,
  uploadFunnelAssets,
  getFunnelVersionsById
} from "../../services/api-declaration";
import {
  FunnelBasicFormSchema,
  FunnelFacebookFormSchema
} from "../../services/validation-schema";
import PreventReload from "./prevent-reload";
import VersionDrawer from "./version-drawer";
import FacebookTab from "./facebook-tab/fb-tab";
import FixedLoader from "../loader/fixed-loader";
import { useDialog } from "../../hooks/useDialog";
import LinkPreviewComponent from "../link-preview";
import ImportFunnelModal from "./import-funnel-modal";
import CustomerForm from "../customers/customer-form";
import CategoryForm from "../categories/category-form";
import FunnelOutcomes from "./funnel-outcomes/outcomes";
import TextArea from "../reusable-form-fields/text-area";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";
import EditorModalController from "./editor-modal-controller";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import NavigatedRouteMessage from "./navigated-route-message";
import FormModal from "../create-new-modal/new-customer-modal";
import SelectField from "../reusable-form-fields/select-field";
import DomainSection from "../reusable-form-section/domain-section";
import LayoutSection from "../reusable-form-section/layout-section";
import AsyncSelectField from "../reusable-form-fields/async-select";
import FunnelQASection from "../reusable-form-section/funnel-qa-section";
import ValidationMessage from "../validation-message/validation-message";
import { resetAnswers, isValidHttpUrl } from "../../services/common-service";
import LeadPriceBenchmark from "../reusable-form-fields/lead-price-benchmark";
import TextElementsSection from "../reusable-form-section/text-elements-section";
import ThankYouPageSection from "../reusable-form-section/thank-you-page-section";
import AsyncCreatableSelectField from "../reusable-form-fields/creatable-async-select";
import { useTranslation, Trans } from "react-i18next";

const FormContainer = styled.div`
  position: relative;
`;
const VersionContainer = styled.div`
  top: 10px;
  right: 20px;
`;
const VersionText = styled.div`
  font-size: 14px;
`;

const FunnelForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const {
    funnel,
    fbFormMode,
    handleFbFormMode,
    funnelVersions,
    setFunnelVersions,
    fbTabInfo,
    resetFbTabInfo,
    setFunnel
  } = useOutletContext();

  const {
    showConfirmationDialogBox,
    showVersionDialogBox,
    showAlertDialogBox,
    showSaveAsTemplateDialogBox,
    setDependentStates
  } = useDialog();

  const navigate = useNavigate();
  const { state } = useLocation(); // state of template and customer,  if create funnel open/navigated from a them
  const [searchParams, setSearchParams] = useSearchParams();
  const funnelTypeParam = searchParams.get("funnelType");
  const [versionLoadingIndex, setVersionLoadingIndex] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [resourceId, setResourceId] = useState(params.id);
  const btnRef = useRef();
  const cancel = useRef();
  const CancelToken = axios.CancelToken;
  const [activeTabs, setActiveTabs] = useState({
    mainForm: FORM_TYPE.FUNNEL_FORM,
    subForm: funnel?.funnelQA?.length
      ? FORM_TYPE.COCKPIT_FORM
      : funnel?.facebookForm?.page?.id
        ? FORM_TYPE.FACEBOOK_FORM
        : FORM_TYPE.COCKPIT_FORM
  });
  const versionTitleRef = useRef("");
  const changeVersionRef = useRef();
  const methods = useForm({
    resolver: yupResolver(
      activeTabs.subForm === FORM_TYPE.FACEBOOK_FORM
        ? FunnelFacebookFormSchema
        : FunnelBasicFormSchema
    ),
    defaultValues: {
      customer: state?.navigatedCustomer
        ? {
            firstName: state.navigatedCustomer.firstName,
            lastName: state.navigatedCustomer.lastName,
            email: state.navigatedCustomer.email,
            _id: state.navigatedCustomer._id
          }
        : "",
      category: "",
      general: {
        template: state?.navigatedTemplate
          ? {
              name: state.navigatedTemplate.name,
              _id: state.navigatedTemplate._id
            }
          : "",
        funnelName: "",
        funnelType: funnelTypeParam,
        facebookConversionApi: FB_CONVERSION_API_OPTS.WAREHOUSES
      },
      funnelQA: state?.navigatedTemplate
        ? state.navigatedTemplate.funnelQA
        : [],
      outcomes: state?.navigatedTemplate
        ? state.navigatedTemplate.outcomes
        : [],
      companyInfo: {
        companyName: "",
        imprintUrl: "",
        imprintContent: "",
        dataProtectionUrl: "",
        dataProtectionContent: "",
        companyWebsite: "",
        country: {
          name: "Deutschland",
          _id: BASE_COUNTRY
        }
      },
      domain: "handwerkintern.de",
      subDomain: "",
      textElements: state?.navigatedTemplate
        ? state.navigatedTemplate.textElements
        : textElemObj,
      design: state?.navigatedTemplate
        ? state.navigatedTemplate.design
        : designObj,
      thankyouPage: state?.navigatedTemplate
        ? state.navigatedTemplate.thankyouPage
        : thankYouPageObj,
      companyWebsiteProtocol: "http://",
      facebookForm: {
        page: null,
        forms: null,
        user: null,
        tabType: SELECT_TAB.SEARCH
      },
      scriptHead: null,
      scriptBody: null,
      scriptThankyou: null,
      shouldUpdateVersion: false,
      versionDescription: "",
      leadPriceBenchmark: 0,
      leadQualifiedPriceBenchmark: 0,
      hubspotProjectId: "",
      funnelDesign: FUNNEL_DESIGNS.CLASSIC
      // version: "",
    }
  });
  const {
    formState: { errors, isDirty, dirtyFields },
    reset,
    watch,
    getValues,
    control,
    register,
    setFocus,
    setValue,
    handleSubmit
  } = methods;
  const initialValues = {
    funnelQA: [],
    outcomes: [],
    design: designObj,
    textElements: textElemObj,
    thankyouPage: thankYouPageObj
  };
  const [isAssetsUploading, setIsAssetsUploading] = useState(false);
  const [allowAddNewCustomer, setAllowAddNewCustomer] = useState(false);
  const [allowAddNewCategory, setAllowAddNewCategory] = useState(false);
  const [createOptionText, setCreateOptionText] = useState("");
  const [shouldReleaseArchived, setShouldReleaseArchived] = useState({
    funnelId: null,
    funnelName: null
  });
  const [fbOptions, setFbOptions] = useState({ pages: [], forms: [] });
  const [fbLoader, setFbLoader] = useState({
    pages: false,
    page: false,
    form: false
  });
  const [isCustomFieldValidated, setIsCustomFieldValidated] = useState(false);

  const protocolWatcher = useWatch({
    name: "companyWebsiteProtocol",
    control
  });
  const compWebUrlWatcher = useWatch({
    name: "companyInfo.companyWebsite",
    control
  });
  const dpUrlWatcher = useWatch({
    name: "companyInfo.dataProtectionUrl",
    control
  });
  const imprintUrlWatcher = useWatch({
    name: "companyInfo.imprintUrl",
    control
  });
  const {
    handleCategoryOptions,
    handleCustomerOptions,
    handleTemplateOptions
  } = useOptionsFetch();

  const onSubmit = (formVals, formType) => {
    reset(JSON.parse(JSON.stringify(formVals)));
    if (formVals.customer?.hasOwnProperty.call(formVals.customer, "value")) {
      formVals.customer = formVals.customer.value;
    }
    if (formVals.category?.hasOwnProperty.call(formVals.category, "value")) {
      formVals.category = formVals.category.value;
    }
    let payload = {
      customer: formVals.customer,
      category: formVals.category,
      general: formVals.general,
      leadPriceBenchmark: formVals.leadPriceBenchmark,
      leadQualifiedPriceBenchmark: formVals.leadQualifiedPriceBenchmark,
      hubspotProjectId: formVals.hubspotProjectId
    };
    if (formType === FORM_TYPE.FACEBOOK_FORM) {
      payload = {
        ...payload,
        facebookForm: {
          ...formVals.facebookForm,
          page: {
            id: formVals.facebookForm.page.id
          }
        }
      };
    } else {
      formVals.funnelQA = resetAnswers(formVals);
      payload = {
        ...payload,
        shouldUpdateVersion: formVals.shouldUpdateVersion,
        versionTitle: formVals.versionTitle,
        versionDescription: formVals.versionDescription,
        funnelQA: formVals.funnelQA,
        outcomes: formVals.outcomes,
        companyInfo: {
          ...formVals.companyInfo,
          country: formVals.companyInfo.country
        },
        domain: formVals.domain,
        subDomain: formVals.subDomain,
        textElements: formVals.textElements,
        design: formVals.design,
        thankyouPage: formVals.thankyouPage,
        companyWebsiteProtocol: formVals.companyWebsiteProtocol,
        scriptHead: formVals.scriptHead,
        scriptBody: formVals.scriptBody,
        scriptThankyou: formVals.scriptThankyou,
        funnelDesign: formVals.funnelDesign
      };
    }
    if (payload.facebookForm?.page?.id) {
      handleSaveFbForm(payload);
    } else if (params.id) {
      handleUpdateFunnel(payload, isDomainValid);
    } else {
      handleCreateFunnel(payload, isDomainValid);
    }
  };
  const toggleTemplateDialog = () =>
    showSaveAsTemplateDialogBox({
      resourceId,
      formValues: getValues()
    });

  const toggleVersionDialog = () =>
    showVersionDialogBox({
      responseAction: versionResponseAction,
      versionTitleRef,
      resourceId,
      register
    });

  const toggleDomainDialog = () =>
    showConfirmationDialogBox({
      dialogMessage: domainDialogMessage(),
      dialogType: "warning",
      responseAction: (action) => domainResponseAction(action, getValues()),
      title: "Warning"
    });
  const toggleChangeVersionDialog = (version, index) => {
    changeVersionRef.current = version;
    showConfirmationDialogBox({
      dialogMessage: changeVersionDialogMessage(),
      dialogType: "warning",
      responseAction: (action) => changeVersionResponseAction(action, index),
      title: "Warning"
    });
  };

  const toggleConfirmationDialog = () =>
    showConfirmationDialogBox({
      dialogMessage: renderDialogMessage(),
      dialogType: "warning",
      responseAction: dialogResponseAction,
      title: "Warning"
    });

  const renderDialogMessage = () => (
    <div>
      <Trans i18nKey="modalContent.funnel.discardUnsavedWarning" />
    </div>
  );
  const changeVersionDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("activateVersionMessage")
      }}
    ></div>
  );
  const domainDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("domainAlreadyTakenMsg")
      }}
    ></div>
  );
  const setProtocol = (formValues) =>
    `${protocolWatcher}${
      formValues.companyInfo?.companyWebsite
        ? formValues.companyInfo.companyWebsite
        : ""
    }`;
  const toggleAlertDialog = () =>
    showAlertDialogBox({
      message: t("modalContent.funnel.unsavedWarning"),
      dialogType: "warning",
      title: "Warning"
    });
  const handleReleaseDomain = async () => {
    if (!shouldReleaseArchived?.funnelId) return;
    try {
      await updateFunnel(shouldReleaseArchived?.funnelId, {
        releaseData: true
      });
      showSuccess(
        `Removed domain & sub-domain from ${shouldReleaseArchived?.funnelName} successfully!`
      );
    } catch (error) {
      showError(
        error.response?.data?.message || t("toastMessage.removeDomain")
      );
    }
  };
  const navigateAfterCreateNew = (id) => {
    if (!params.id) {
      navigate({
        pathname: `../../${id}/form`,
        search: searchParams.toString()
      });
    }
  };
  const fetchVersionData = async () => {
    try {
      const versionResponse = await getFunnelVersionsById(params.id);
      const versions = versionResponse.data.data;
      versions.currentVersion = versions.allVersions.find(
        ({ _id }) => _id === versions.currentVersion.activeVersion
      );
      setFunnelVersions(versions);
    } catch (error) {
      showError(
        error.response?.data?.message || t("toastMessage.fetchVersion")
      );
    }
  };
  const handleFbTabFieldOpts = (opts, property, reset = false) =>
    reset
      ? setFbOptions({ pages: [], forms: [] })
      : setFbOptions((data) => {
          const clone = { ...data };
          clone[property] = opts;
          return clone;
        });
  const handleCreateFunnel = async (formValues, domainValidity) => {
    try {
      if (!domainValidity) {
        toggleDomainDialog();
        return;
      }
      setIsSubmitting(true);
      const payload = {
        ...formValues,
        general: {
          ...formValues.general,
          template: formValues.general.template?.name
            ? formValues.general.template.name
            : ""
        },
        companyInfo: {
          ...formValues.companyInfo,
          companyWebsite: setProtocol(formValues)
        },
        shouldUpdateVersion: true,
        draft: false,
        createDefaultEmailIntegration: true
      };
      handleReleaseDomain();
      const response = await updateFunnel(resourceId, payload);
      showSuccess(response.data.message);
      handleSearchParams("funnelType", funnelTypeParam);
      navigateAfterCreateNew(resourceId);
    } catch (error) {
      const errors = error.response?.data?.message?.split(",");
      if (errors?.length) {
        errors.forEach((msg) => showError(msg));
      } else {
        showError(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleUpdateFunnel = async (formValues, domainValidity) => {
    try {
      if (!domainValidity) {
        toggleDomainDialog();
        return;
      }
      setIsSubmitting(true);
      const payload = {
        ...formValues,
        companyInfo: {
          ...formValues.companyInfo,
          companyWebsite: setProtocol(formValues)
        }
      };
      handleReleaseDomain();
      const response = await updateFunnel(params.id, payload);
      setFunnel({ ...funnel, ...formValues });
      await fetchVersionData();
      showSuccess(response.data.message);
    } catch (error) {
      console.log("PATCH ERROR ", error);
      showError(
        error.response?.data?.message || t("toastMessage.updateFunnels")
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSaveFbForm = async (formValues) => {
    try {
      setIsSubmitting(true);
      let funnelId = "";
      if (!params.id) {
        formValues.draft = false;
        formValues.createDefaultEmailIntegration = true;
        funnelId = resourceId;
      } else {
        funnelId = params.id;
      }
      const response = await saveFbForm(funnelId, formValues);
      setValue("facebookForm.page.name", response.data.data.name);
      setFunnel({
        ...funnel,
        ...formValues,
        facebookForm: {
          ...formValues.facebookForm,
          page: {
            ...formValues.facebookForm.page,
            name: response.data.data.name
          }
        }
      });
      resetFbTabInfo();
      handleFbTabFieldOpts(null, null, true); // for reset both pages and forms select field options
      handleFbFormMode(FB_FORM_MODE.READONLY);
      showSuccess(response.data.message);
      navigateAfterCreateNew(resourceId);
    } catch (error) {
      console.log("PATCH ERROR ", error);
      showError(
        error.response?.data?.message || t("toastMessage.updateFunnels")
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleVersionChange = async (version, index) => {
    setVersionLoadingIndex(index);
    try {
      const res = await changeVersionById(params.id, { version: version._id });
      const data = res.data.data;
      await fetchVersionData();
      if (!data) {
        return;
      }
      setShowDrawer(false);
      let protocol = "http://";
      if (data.companyInfo?.companyWebsite.includes("http")) {
        protocol = isValidHttpUrl(data.companyInfo?.companyWebsite)
          ? `${new URL(data.companyInfo?.companyWebsite).protocol}//`
          : "";
      }
      const funnelData = {
        customer: data.customer,
        category: data.category,
        general: data.general,
        funnelQA: data.funnelQA,
        outcomes: data.outcomes,
        companyInfo: {
          ...data.companyInfo,
          companyWebsite: data.companyInfo?.companyWebsite.replace(
            /(^\w+:|^)\/\//,
            ""
          )
        },
        domain: data.domain,
        subDomain: data.subDomain,
        textElements: data.textElements,
        design: data.design,
        thankyouPage: data.thankyouPage,
        companyWebsiteProtocol: protocol,
        facebookForm: {
          page: data?.facebookForm?.page,
          forms: data?.facebookForm?.forms,
          user: data?.facebookForm?.user
        },
        scriptHead: data.scriptHead,
        scriptBody: data.scriptBody,
        scriptThankyou: data.scriptThankyou,
        activeVersion: data.activeVersion
      };
      showSuccess(res.data.message);
      reset(funnelData);
    } catch (error) {
      showError(
        error.response?.data?.message || t("toastMessage.changeVersion")
      );
    } finally {
      setVersionLoadingIndex(-1);
    }
  };

  const updateFieldValues = async (selectedValue) => {
    const allFormValues = getValues();
    if (selectedValue._id) {
      try {
        const template = await getTemplateById(selectedValue._id);
        if (allFormValues.funnelQA.length) {
          setDependentStates({ currentTemp: template.data.data });
          toggleConfirmationDialog();
          return;
        }
        const initialData = {};
        Object.keys(initialValues).forEach((key) => {
          initialData[key] = template.data.data[key];
        });
        const funnelDesign =
          template.data.data.templateDesign || FUNNEL_DESIGNS.CLASSIC;

        // not removing modern variant values
        reset();
        reset(
          {
            ...allFormValues,
            ...initialData,
            funnelDesign
          },
          { keepDirty: true }
        );
        showSuccess(t("toastMessage.templateAppliedSuccess"));
      } catch (error) {
        console.log(error);
        showError(
          error.response?.data?.message ||
            t("toastMessage.errorInGetting", { what: t("common.template") })
        );
      }
    } else {
      reset({ ...allFormValues, ...initialValues }, { keepDirty: true });
    }
  };
  const changeVersionResponseAction = (action, index) => {
    if (action && changeVersionRef.current) {
      handleVersionChange(changeVersionRef.current, index);
      changeVersionRef.current = undefined;
    }
  };
  const versionResponseAction = (action) => {
    if (action) {
      setValue("shouldUpdateVersion", true);
      setValue("versionTitle", versionTitleRef.current);
    } else {
      setValue("shouldUpdateVersion", false);
    }
    handleSubmit(onSubmit)(FORM_TYPE.COCKPIT_FORM);
  };
  const dialogResponseAction = (action, values) => {
    if (action && values.currentTemp) {
      const funnelDesign =
        values.currentTemp.templateDesign || FUNNEL_DESIGNS.CLASSIC;
      const initialData = {
        ...getValues(),
        funnelDesign
      };
      Object.keys(initialValues).forEach((key) => {
        initialData[key] = values.currentTemp[key];
      });
      showSuccess(t("toastMessage.templateAppliedSuccess"));
      // not removing modern variant values
      reset();
      reset(initialData, { keepDirty: true });
    }
  };
  const domainResponseAction = (action, formVals) => {
    if (action) {
      if (params.id) {
        handleUpdateFunnel(formVals, true);
      } else {
        handleCreateFunnel(formVals, true);
      }
    }
  };
  const uploadImportedAssets = async (data, formVals) => {
    try {
      setIsAssetsUploading(true);
      const response = await uploadFunnelAssets({
        data,
        resourceId
      });
      const importedData = response.data.data;
      formVals.funnelQA = importedData.questions;
      formVals.design.backgroundImage = importedData.design.backgroundImage;
      formVals.design.companyLogo = importedData.design.companyLogo;
      formVals.design.partnerLogo = importedData.design.partnerLogo;
      reset(formVals);
      showSuccess(response.data.message);
    } catch (error) {
      console.log(error);
      showError(t("toastMessage.assetsUploadError"));
    } finally {
      setIsAssetsUploading(false);
    }
  };
  const setImportedDataCallback = async (data) => {
    const formValues = { ...getValues() };
    formValues.funnelQA = data.questions;
    formValues.companyInfo.companyName = data.companyInfo.companyName;
    formValues.companyInfo.dataProtectionUrl =
      data.companyInfo.dataProtectionUrl;
    formValues.companyInfo.imprintUrl = data.companyInfo.imprintUrl;
    formValues.design.backgroundImage = data.design.backgroundImage;
    formValues.design.companyLogo = data.design.companyLogo;
    formValues.design.partnerLogo = data.design.partnerLogo;
    reset(formValues);
    await uploadImportedAssets(data, formValues);
  };
  const handleSearchParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const closeCustomerModal = () => setAllowAddNewCustomer(false);
  const closeCategoryModal = () => setAllowAddNewCategory(false);
  const createCustomerCallback = (data) => {
    setValue("customer", data);
    closeCustomerModal();
  };
  const createCategoryCallback = (data) => {
    setValue("category", data);
    closeCategoryModal();
  };
  const handleFbLoader = (property, flag) => {
    setFbLoader((prevState) => {
      const clone = { ...prevState };
      clone[property] = flag;
      return clone;
    });
  };
  const handleUserFbPages = async () => {
    try {
      handleFbLoader("pages", true);
      const response = await getFbPages({
        params: {
          userId: getValues("facebookForm.user")._id
        },
        cancelToken: new CancelToken((token) => {
          cancel.current = token;
        })
      });
      handleFbTabFieldOpts(response.data.data.data, "pages");
      setValue("facebookForm.tabType", SELECT_TAB.SEARCH);
    } catch (error) {
      if (error.name === "CanceledError") return;
      showError(
        error.response?.data?.message || t("toastMessage.fbPagesError")
      );
    } finally {
      handleFbLoader("pages", false);
    }
  };
  const getPageForms = async (_pageId) => {
    try {
      handleFbLoader("page", true);
      handleFbLoader("form", true);
      const response = await getFbForms(_pageId, {
        params: {
          userId: getValues("facebookForm.user")._id
        },
        cancelToken: new CancelToken((token) => {
          cancel.current = token;
        })
      });
      setIsCustomFieldValidated(true);
      handleFbTabFieldOpts(response.data.data, "forms");
      if (!response.data.data?.length) {
        showWarning(response.data.message);
      }
    } catch (error) {
      handleFbTabFieldOpts([], "forms");
      if (error.name === "CanceledError") return;
      // showError(t("toastMessage.fbFormError"));
      showError(error.response?.data?.message || t("toastMessage.fbFormError"));
    } finally {
      handleFbLoader("page", false);
      handleFbLoader("form", false);
    }
  };
  // checkIfFunnelQAChanges method is to check if funnel qa has any dirty field at any level
  const checkIfFunnelQAChanges = (funnelQACollection) => {
    if (!funnelQACollection?.length) return;
    const filteredQACollection = funnelQACollection.filter(Boolean);
    for (const question of filteredQACollection) {
      if (!question) return;
      // funnel question root level
      const hasQuestionChanges = Object.values(question).some(
        (item) => item === true
      );
      // funnel question's range object level
      const hasQuestionRangeChanges =
        question.range &&
        Object.values(question.range).some((item) => item === true);
      // funnel answer root level
      const hasAnswerChanges =
        question.answers?.length &&
        question.answers.some(
          (ansObj) =>
            ansObj &&
            Object.entries(ansObj).some((item) => {
              // condition to ignore ansType property because it's only for frontend conditional form validation
              if (item[0] !== "ansType" && item[1] === true) {
                return true;
              }
              return false;
            })
        );
      // funnel answer's asset object level
      const hasAnswerAssetChanges =
        question.answers?.length &&
        question.answers.some(
          (ansObj) =>
            ansObj &&
            Object.values(ansObj).some(
              (asset) =>
                asset && Object.values(asset).some((item) => item === true)
            )
        );
      // checking if any level has change
      if (
        hasQuestionChanges ||
        hasQuestionRangeChanges ||
        hasAnswerChanges ||
        hasAnswerAssetChanges
      ) {
        return true;
      }
    }
  };
  // checkIfFunnelOutcomeChanges method is to check if funnel outcome has any dirty field at any level
  const checkIfFunnelOutcomeChanges = (outcomeCollection) => {
    if (!outcomeCollection?.length) return;
    const filteredOutcomeCollection = outcomeCollection.filter(Boolean);
    for (const outcome of filteredOutcomeCollection) {
      if (!outcome) return;
      const hasOutcomeChanges = Object.values(outcome).some(
        (item) => item === true
      );
      if (hasOutcomeChanges) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (params.id && funnel) {
      reset(funnel);
    }
  }, [funnel, params.id, reset]);
  useEffect(() => {
    if (!params.id) {
      const handleCreateDraft = async () => {
        try {
          const response = await createDraftFunnel();
          setResourceId(response.data.data._id);
        } catch (err) {
          console.log(err);
          showError(err.response.data.message || t("toastMessage.draftFunnel"));
        } finally {
          setIsLoading(false);
        }
      };
      handleCreateDraft();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [params.id]);
  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (key === "funnelQA") {
          setFocus(`funnelQA[${0}].text`);
        }
      });
    }
  }, [errors, setFocus]);
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <FormContainer>
        {isAssetsUploading && (
          <FixedLoader loaderMessage="Assets hochladen..." />
        )}
        <FormProvider {...methods}>
          <PreventReload />
          <form>
            {!params.id && (
              <div className="text-end mt-4">
                <Button
                  variant="outline-primary"
                  onClick={() => setShowImportModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faArrowDownShortWide}
                    className="me-2"
                  />
                  {t("common.import")}
                </Button>
              </div>
            )}
            <NavigatedRouteMessage />
            <CustomCard className="my-4">
              <div className="m-3">
                <FieldSet>
                  <Legend>
                    <h6 className="mb-0 fw-bold">{t("basics")}</h6>
                  </Legend>
                  <div className="px-0">
                    {!params.id &&
                      activeTabs.subForm === FORM_TYPE.COCKPIT_FORM && (
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group className="mb-4" controlId="vorlage">
                              <Form.Label>{t("common.template")}</Form.Label>
                              <AsyncSelectField
                                control={control}
                                name="general.template"
                                fetchData={handleTemplateOptions}
                                onSelect={updateFieldValues}
                                isDisabled={state?.navigatedTemplate}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    <Row>
                      <Col sm={12} md={6}>
                        <TextField
                          label="Funnel Name"
                          placeholder={`${t("customerName")} - ${t(
                            "createFunnelModal.actions.lead"
                          )} - ${t("common.product")}`}
                          type="text"
                          name="general.funnelName"
                          errors={errors?.general?.funnelName}
                          register={register}
                          noSpacing
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="d-flex align-items-end">
                          <Form.Group
                            controlId="customer"
                            className="flex-fill"
                          >
                            <Form.Label
                              className={errors?.customer && "text-danger"}
                            >
                              {t("common.customer")}
                              {errors?.customer && "*"}
                            </Form.Label>
                            <div className="d-flex align-items-center">
                              <AsyncCreatableSelectField
                                control={control}
                                name="customer"
                                getOptionLabel={(opt) =>
                                  `${opt.firstName} ${opt.lastName} (${opt.email})`
                                }
                                fetchData={handleCustomerOptions}
                                onCreate={(e) => {
                                  setCreateOptionText(e);
                                  setAllowAddNewCustomer(true);
                                }}
                                isDisabled={state?.navigatedCustomer}
                                capitalized={false}
                              />
                            </div>
                            <ValidationMessage
                              error={errors?.customer?.message}
                            />
                          </Form.Group>
                          {getValues("customer")?._id && (
                            <Button
                              as={Link}
                              target="_blank"
                              className="mb-1 ms-3"
                              variant="outline-secondary"
                              to={`../../../customers/${
                                getValues("customer")._id
                              }/overview`}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={12} md={6}>
                        <Form.Group controlId="category">
                          <Form.Label
                            className={errors?.category && "text-danger"}
                          >
                            {t("common.category")}
                            {errors?.category && "*"}
                          </Form.Label>
                          <div className="d-flex align-items-center">
                            <AsyncCreatableSelectField
                              control={control}
                              name="category"
                              fetchData={handleCategoryOptions}
                              onCreate={(e) => {
                                setCreateOptionText(e);
                                setAllowAddNewCategory(true);
                              }}
                              capitalized={false}
                            />
                          </div>
                          <ValidationMessage
                            error={errors?.category?.message}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={6}>
                        <Form.Group controlId="facebookConversionApi">
                          <Form.Label>{"Facebook Conversion API"}</Form.Label>
                          <div className="d-flex align-items-center">
                            <SelectField
                              control={control}
                              getValues={getValues}
                              options={pixelOptions}
                              name="general.facebookConversionApi"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={12} md={12}>
                        <LeadPriceBenchmark
                          fromModal={false}
                          categoryId={watch("category")?._id}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={12} md={6}>
                        <TextField
                          type="text"
                          name="hubspotProjectId"
                          label={t("hubspotProjectID")}
                          placeholder={`${t("common.enter")} ${t(
                            "hubspotProjectID"
                          )}`}
                          register={register}
                          errors={errors?.hubspotProjectId}
                          noSpacing
                        />
                      </Col>
                    </Row>
                  </div>
                </FieldSet>
              </div>
            </CustomCard>
            <CustomCard className="mb-4">
              <Tabs
                id="form-section-tabs"
                onSelect={(evKey) => {
                  setActiveTabs({ ...activeTabs, subForm: evKey });
                }}
                activeKey={activeTabs.subForm}
                className="m-3"
              >
                <Tab
                  eventKey={FORM_TYPE.COCKPIT_FORM}
                  title={`Cockpit ${t("common.form")}`}
                >
                  {versionLoadingIndex !== -1 ? (
                    <Loader />
                  ) : (
                    <div className="px-3">
                      <FunnelQASection
                        selectedCategory={getValues("category")}
                        parentFolderName="funnel"
                        resourceId={resourceId}
                        errors={errors?.funnelQA}
                      />
                      <HorizontalDivider />
                      <FunnelOutcomes />
                      <HorizontalDivider />
                      <div className="py-3 px-0">
                        <FieldSet>
                          <Legend>
                            <h6 className="mb-0 fw-bold">
                              {t("common.companyInfo")}
                            </h6>
                          </Legend>
                          <div className="px-0">
                            <Row>
                              <Col xs={12} md={6}>
                                <TextField
                                  label={t("common.companyName")}
                                  placeholder={t("common.companyName")}
                                  type="text"
                                  name="companyInfo.companyName"
                                  register={register}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <InputGroup className="mb-3">
                                  <Form.Label className="w-100">
                                    {t("common.companyWebsite")}
                                  </Form.Label>
                                  <Row className="w-100">
                                    <Col
                                      xs={4}
                                      md={4}
                                      lg={2}
                                      className="p-0 me-1"
                                    >
                                      <Form.Select
                                        {...register("companyWebsiteProtocol")}
                                      >
                                        <option value="http://">http://</option>
                                        <option value="https://">
                                          https://
                                        </option>
                                      </Form.Select>
                                    </Col>
                                    <Col className="p-0">
                                      <TextField
                                        placeholder={t("common.companyWebsite")}
                                        type="text"
                                        name="companyInfo.companyWebsite"
                                        register={register}
                                        noSpacing
                                      />
                                    </Col>
                                  </Row>
                                  <LinkPreviewComponent
                                    link={`${protocolWatcher}${compWebUrlWatcher}`}
                                    linkText={`${protocolWatcher}${compWebUrlWatcher}`}
                                    show={
                                      protocolWatcher?.length &&
                                      compWebUrlWatcher?.length
                                    }
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={6}>
                                <TextField
                                  label={`${t("common.dataProtection")} URL`}
                                  placeholder={`${t(
                                    "common.dataProtection"
                                  )} URL`}
                                  type="text"
                                  name="companyInfo.dataProtectionUrl"
                                  register={register}
                                  noSpacing
                                />
                                <LinkPreviewComponent
                                  link={dpUrlWatcher}
                                  linkText={dpUrlWatcher}
                                  show={dpUrlWatcher?.length}
                                />
                                <EditorModalController
                                  title={`${t("common.dataProtection")}`}
                                  context="companyInfo.dataProtectionContent"
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <TextField
                                  label={`${t("common.imprint")} URL`}
                                  placeholder={`${t("common.imprint")} URL`}
                                  type="text"
                                  name="companyInfo.imprintUrl"
                                  register={register}
                                  noSpacing
                                />
                                <LinkPreviewComponent
                                  link={imprintUrlWatcher}
                                  linkText={imprintUrlWatcher}
                                  show={imprintUrlWatcher?.length}
                                />
                                <EditorModalController
                                  title={`${t("common.imprint")}`}
                                  context="companyInfo.imprintContent"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={6}>
                                <Form.Group className="mb-4" controlId="land">
                                  <Form.Label>{t("common.country")}</Form.Label>
                                  <SelectField
                                    control={control}
                                    name="companyInfo.country"
                                    options={countryOptions}
                                    getValues={getValues}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </FieldSet>
                      </div>
                      <HorizontalDivider />
                      <DomainSection
                        btnRef={btnRef}
                        isDomainValid={isDomainValid}
                        setIsDomainValid={setIsDomainValid}
                        setShouldReleaseArchived={setShouldReleaseArchived}
                      />
                      <HorizontalDivider />
                      <LayoutSection
                        parentFolderName="funnel"
                        resourceId={resourceId}
                        selectedCategory={getValues("category")}
                      />
                      <HorizontalDivider />
                      <TextElementsSection />
                      <HorizontalDivider />
                      <ThankYouPageSection />
                      <HorizontalDivider />
                      <div className="py-3 px-0">
                        <FieldSet>
                          <Legend>
                            <h6 className="mb-0 fw-bold">{t("script")}</h6>
                          </Legend>
                          <TextArea
                            label="Head"
                            name="scriptHead"
                            register={register}
                          />
                          <TextArea
                            label="Body"
                            name="scriptBody"
                            register={register}
                          />
                          <TextArea
                            label={t("thankyouPage")}
                            name="scriptThankyou"
                            register={register}
                          />
                        </FieldSet>
                      </div>
                      <HorizontalDivider />
                      <div className="p-0">
                        <Row>
                          <Col xs={12} md={6}>
                            {!isSubmitting ? (
                              <Button
                                variant="primary"
                                className="me-3 mb-3"
                                type="button"
                                ref={btnRef}
                                onClick={() => {
                                  getValues("funnelQA")?.forEach((question) => {
                                    question.answers?.forEach((answer) => {
                                      answer.ansType = question.type;
                                    });
                                  });
                                  (checkIfFunnelQAChanges(
                                    dirtyFields.funnelQA
                                  ) ||
                                    checkIfFunnelOutcomeChanges(
                                      dirtyFields.outcomes
                                    )) &&
                                  params.id
                                    ? toggleVersionDialog()
                                    : versionResponseAction();
                                }}
                                disabled={
                                  !isDomainValid &&
                                  !shouldReleaseArchived?.funnelId
                                }
                              >
                                {t("common.saveFunnel")}
                              </Button>
                            ) : (
                              <SpinnerButton
                                btnText="Saving Funnel"
                                btnStyle="me-3 mb-3 btn-primary"
                              />
                            )}
                            <Button
                              variant="outline-primary"
                              className="mb-3"
                              onClick={
                                isDirty
                                  ? toggleAlertDialog
                                  : toggleTemplateDialog
                              }
                            >
                              {t("modalContent.saveAsTemplate", {
                                new: ` ${t("common.new")} `
                              })}
                            </Button>
                          </Col>
                          <Col xs={12} md={6}>
                            <div className="float-md-end float-sm-start">
                              <Button variant="outline-danger">
                                {t("common.cancel")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey={FORM_TYPE.FACEBOOK_FORM}
                  title={`Facebook ${t("common.form")}`}
                >
                  <div className="px-3">
                    <FacebookTab
                      fbLoader={fbLoader}
                      fbOptions={fbOptions}
                      getFbPagesCb={handleUserFbPages}
                      fbFormMode={fbFormMode}
                      handleFbFormMode={handleFbFormMode}
                      getPageForms={getPageForms}
                      fbTabInfo={fbTabInfo}
                      handleFbTabFieldOpts={handleFbTabFieldOpts}
                      setIsCustomFieldValidated={setIsCustomFieldValidated}
                      isCustomFieldValidated={isCustomFieldValidated}
                      funnel={funnel}
                      cancel={cancel.current}
                    />

                    <div className="p-0">
                      <Row>
                        <Col xs={12} md={6}>
                          {!isSubmitting ? (
                            <Button
                              variant="primary"
                              className="me-3 mb-3"
                              type="button"
                              // ref={btnRef}
                              onClick={() => {
                                handleSubmit(onSubmit)(FORM_TYPE.FACEBOOK_FORM);
                              }}
                              disabled={
                                fbFormMode !== FB_FORM_MODE.READONLY &&
                                ((!isCustomFieldValidated &&
                                  getValues("facebookForm.page.id")) ||
                                  fbLoader.page ||
                                  fbLoader.pages)
                              }
                            >
                              {t("common.saveFunnel")}
                            </Button>
                          ) : (
                            <SpinnerButton
                              btnText="Saving Funnel"
                              btnStyle="me-3 mb-3 btn-primary"
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <VersionContainer className="position-absolute">
                <VersionText className="mb-0 text-secondary d-flex fw-bold align-items-center">
                  {funnelVersions?.currentVersion && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{t("currentVersion")}</Tooltip>}
                    >
                      <Button
                        variant="link"
                        onClick={() => setShowDrawer(!showDrawer)}
                      >
                        <FontAwesomeIcon icon={faCodeBranch} className="me-2" />
                        {funnelVersions.currentVersion.funnelVersion?.title}
                      </Button>
                    </OverlayTrigger>
                  )}
                </VersionText>
              </VersionContainer>
            </CustomCard>
          </form>
        </FormProvider>
        <ImportFunnelModal
          show={showImportModal}
          onHide={() => setShowImportModal(false)}
          setImportedDataCallback={setImportedDataCallback}
        />
        <FormModal
          show={allowAddNewCustomer}
          onHide={closeCustomerModal}
          modalHeading={t("createNewCustomer")}
        >
          <CustomerForm
            fromModal
            initialValue={createOptionText}
            createCustomerCallback={createCustomerCallback}
          />
        </FormModal>
        <FormModal
          show={allowAddNewCategory}
          onHide={closeCategoryModal}
          modalHeading={t("createNewCategory")}
        >
          <CategoryForm
            fromModal
            initialValue={createOptionText}
            createCategoryCallback={createCategoryCallback}
          />
        </FormModal>
      </FormContainer>
      {funnelVersions?.currentVersion && (
        <VersionDrawer
          handleVersionChange={toggleChangeVersionDialog}
          showDrawer={showDrawer}
          versions={funnelVersions}
          setShowDrawer={setShowDrawer}
          versionLoadingIndex={versionLoadingIndex}
        />
      )}
    </>
  );
};
export default FunnelForm;
